@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: AlimamaDaoLiTi, 'Segoe UI', 'Roboto', 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

@font-face {
  font-family: 'AlimamaDaoLiTi';
  src: url('./assets/fonts/AlimamaDaoLiTi.ttf') format('ttf'),
    url('./assets/fonts/AlimamaDaoLiTi.woff2') format('woff2'), url('./assets/fonts/AlimamaDaoLiTi.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap; /* 提高页面初始渲染速度 */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
